import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row, Card } from '../../../components'
import { FarmerIcon, VisitedHandshakeIcon } from 'assets'
import './profile.scss'

interface FCMetricsProps {
    animationList: boolean
    hideBorder?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fc?: any
    connectUser: { country: string }
    canViewDeposits: boolean
    canViewDepositGap: boolean
    cardClickHandler: (filterOptions: string[], clientTab: number) => void
}

export const FCMetrics = ({ animationList, fc }: FCMetricsProps) => {
    const { t } = useTranslation()
    const {
        total_active_ids_this_month: idsActiveThisMonth,
        total_enrolled_this_month: enrolledThisMonth,
        total_enrollments_without_payments: enrolledWithoutPayment,
        total_finishers: totalFinishers,
        total_new_clients_registered: totalNewClientsRegistered,
        total_payments_with_no_enrollment: totalPaymentsNoEnrollment,
        total_potential_clients_visited: totalPotentialClientsVisited
    } = fc || {
        total_active_ids_this_month: '',
        total_enrolled_this_month: '',
        total_enrollments_without_payment: '',
        total_finishers: '',
        total_new_clients_registered: '',
        total_payments_with_no_enrollment: '',
        total_potential_clients_visited: ''
    }

    return (
        <Row>
            <Col md={12} className="profile-card-session">
                <div className="header">
                    <div className="header-icon">{FarmerIcon}</div>
                    <p className="session-header header-month">
                        {t('home.thisMonth')}
                    </p>
                    <h2 className="session-header header-card-text">
                        {t('fcProfile.farmers')} - CLIENTS
                    </h2>
                </div>
                <Row className="client-row weekly-objectives">
                    <Col className="client-card-parent">
                        <Card
                            className={
                                animationList
                                    ? 'client-card animate-show-3 animate-showed-3'
                                    : 'client-card animate-show-3'
                            }
                            shadow
                            data-testid="client-active-card"
                            id="active-client-card"
                        >
                            <div className="fc-client-number">
                                <h1 className="fc-client-number--content fc-client-number--num">
                                    {idsActiveThisMonth || 0}
                                </h1>
                            </div>
                            <p className="fc-client-card--header">
                                {t('profile.activeCustomers')}
                            </p>
                        </Card>
                    </Col>
                </Row>
                <Row className="client-row weekly-objectives">
                    {
                        <Col className="client-card-parent">
                            <Card
                                className={
                                    animationList
                                        ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                        : 'client-card second-client-card animate-show-4'
                                }
                                shadow
                                data-testid="enrolled-client-card"
                                id="enrolled-client-card"
                            >
                                <div className="fc-client-number">
                                    <h1 className="fc-client-number--content fc-client-number--num">
                                        {enrolledThisMonth || 0}
                                    </h1>
                                </div>
                                <p className="fc-client-card--header">
                                    {t('profile.toRegister')}
                                </p>
                            </Card>
                        </Col>
                    }
                    {
                        <Col className="client-card-parent">
                            <Card
                                className={
                                    animationList
                                        ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                        : 'client-card second-client-card animate-show-4'
                                }
                                shadow
                                data-testid="finisher-client-card"
                                id="finisher-client-card"
                            >
                                <div className="fc-client-number">
                                    <h1
                                        className="fc-client-number--content fc-client-number--num"
                                        style={{ color: '#9263D0' }}
                                    >
                                        {totalFinishers || 0}
                                    </h1>
                                </div>
                                <p
                                    className="fc-client-card--header"
                                    style={{ color: '#9263D0' }}
                                >
                                    {t('fcProfile.finisher')}
                                </p>
                            </Card>
                        </Col>
                    }
                </Row>
                <Row className="client-row weekly-objectives">
                    {
                        <Col className="client-card-parent">
                            <Card
                                className={
                                    animationList
                                        ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                        : 'client-card second-client-card animate-show-4'
                                }
                                shadow
                                data-testid="enrolled-no-payment-client-card"
                                id="enrolled-no-payment-client-card"
                            >
                                <div className="fc-client-number">
                                    <h1
                                        className="fc-client-number--content fc-client-number--num"
                                        style={{ color: '#16A34A' }}
                                    >
                                        {enrolledWithoutPayment || 0}
                                    </h1>
                                </div>
                                <p className="fc-client-card--header">
                                    {t('fcProfile.enrollNoPayment')}
                                </p>
                            </Card>
                        </Col>
                    }
                    {
                        <Col className="client-card-parent">
                            <Card
                                className={
                                    animationList
                                        ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                        : 'client-card second-client-card animate-show-4'
                                }
                                shadow
                                data-testid="payment-no-enrollment-client-card"
                                id="payment-no-enrollment-client-card"
                            >
                                <div className="fc-client-number">
                                    <h1
                                        className="fc-client-number--content fc-client-number--num"
                                        style={{ color: '#16A34A' }}
                                    >
                                        {totalPaymentsNoEnrollment || 0}
                                    </h1>
                                </div>
                                <p className="fc-client-card--header">
                                    {t('fcProfile.paymentNoEnrollment')}
                                </p>
                            </Card>
                        </Col>
                    }
                </Row>
            </Col>
            <Col md={12} className="profile-card-session">
                <div className="header">
                    <div className="visit-icon">{VisitedHandshakeIcon({})}</div>
                    <p className="session-header header-month">
                        {t('home.thisWeek')}
                    </p>
                    <h2 className="session-header header-card-text">
                        {t('fcProfile.visits')}
                    </h2>
                </div>
                <Row className="client-row weekly-objectives">
                    {
                        <Col className="client-card-parent">
                            <Card
                                className={
                                    animationList
                                        ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                        : 'client-card second-client-card animate-show-4'
                                }
                                shadow
                                data-testid="visited-client-card"
                                id="visited-client-card"
                            >
                                <div className="fc-client-number">
                                    <h1 className="fc-client-number--content fc-client-number--num">
                                        {totalPotentialClientsVisited || 0}
                                    </h1>
                                </div>
                                <p className="fc-client-card--header">
                                    {t('profile.potentialClientsVisited')}
                                </p>
                            </Card>
                        </Col>
                    }
                    {
                        <Col className="client-card-parent">
                            <Card
                                className={
                                    animationList
                                        ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                        : 'client-card second-client-card animate-show-4'
                                }
                                shadow
                                data-testid="registered-client-card"
                                id="registered-client-card"
                            >
                                <div className="fc-client-number">
                                    <h1 className="fc-client-number--content fc-client-number--num">
                                        {totalNewClientsRegistered || 0}
                                    </h1>
                                </div>
                                <p className="fc-client-card--header">
                                    {t('profile.newlyVisited')}
                                </p>
                            </Card>
                        </Col>
                    }
                </Row>
            </Col>
        </Row>
    )
}
