import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { hideEyeIcon, showEyeIcon } from '../../assets'
import './password.css'

export const Password = ({ icon, className, ...props }) => {
    const [showPwd, setShowPwd] = useState(true)

    const showPassword = () => setShowPwd(!showPwd)
    return (
        <span className="password--parent">
            <input
                type={showPwd ? 'password' : 'text'}
                {...props}
                className={`password-input ${className}`}
            />
            {icon && (
                <div className="password-input--icon" onClick={showPassword}>
                    {showPwd ? showEyeIcon : hideEyeIcon}
                </div>
            )}
        </span>
    )
}

Password.propTypes = {
    icon: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func
}
