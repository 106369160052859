import React from 'react'
import { Card } from 'components/card/Card'
import { CheckIcon } from 'assets'
import { colors } from 'styles/colors'
import { useTranslation } from 'react-i18next'

import './card.scss'

type DownloadableCardProps = {
    headerColor: string
    borderBottomColor: string
    icon: JSX.Element
    endIcon?: JSX.Element
    title: string
    subtitles: string[]
    clickHandler?: () => void
}

export const DownloadableCard = ({
    headerColor,
    borderBottomColor,
    icon,
    endIcon,
    title,
    subtitles = [],
    clickHandler
}: DownloadableCardProps) => {
    const { t } = useTranslation()

    return (
        <Card
            className="downloadable-card"
            aria-label="downloadable-card"
            style={{ borderBottom: `1px solid ${borderBottomColor}` }}
            onClick={clickHandler}
        >
            <div
                className="downloadable-card--header"
                style={{
                    backgroundColor: headerColor
                }}
            >
                <div className="downloadable-card--status">
                    <div className="downloadable-card--status--icon">
                        <CheckIcon color={colors.grey} />
                    </div>
                    <div className="downloadable-card--status--text">
                        {t('village.notAvailableOffline')}
                    </div>
                </div>
            </div>
            <div className="downloadable-card--overview">
                <div className="downloadable-card--overview--icon">{icon}</div>
                <div className="downloadable-card--overview--info">
                    <h2 className="downloadable-card--overview--title">
                        {title}
                    </h2>
                    {subtitles.map((subtitle) => (
                        <div
                            key={subtitle}
                            className="downloadable-card--overview--subtitle"
                        >
                            {subtitle}
                        </div>
                    ))}
                </div>
                <div className="downloadable-card--overview--end-icon">
                    {endIcon}
                </div>
            </div>
        </Card>
    )
}
