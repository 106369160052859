import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Card, Spinner, EmptyList } from '../../components'
import { useBindDispatch, useGetStorage } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { constants, getConnectUser } from '../../helpers/'
import { TruckDeliveryIcon, PackageIcon } from '../../assets'
import { findPermission } from '../../helpers/findPermission'
import { ClientGoalItem } from 'components/client/ClientGoalItem'
import { colors } from 'styles/colors'

export const VillageClientPacket = ({
    goalItems,
    modifyHandler,
    allowModification,
    finisher
}) => {
    const { t } = useTranslation()
    const { productListHandler } = useBindDispatch()
    const [localGoalItems, setLocalGoalItems] = useState([])
    const { productListData, loading, preFetchLoading } = useSelector(
        (store) => store.enrollment
    )
    const { zoneSFID } = getConnectUser()

    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)
    const { UPDATE_ORDER } = constants

    const canUpdatePackage =
        findPermission(userPermissions, UPDATE_ORDER) && allowModification

    useEffect(() => {
        if (productListData?.length > 0) {
            processGoalItems(goalItems)
        }
    }, [goalItems, productListData])

    useEffect(() => {
        if (productListData?.length === 0 && !preFetchLoading) {
            productListHandler({ zoneSFID })
        }
    }, [])

    const findProduct = (productId) => {
        const zoneProductList = productListData.find(
            (product) => product.zone_sf_id === zoneSFID
        )

        const product = zoneProductList?.product_list?.find(
            (product) => product.sf_id === productId
        )

        return product
    }

    const processGoalItems = (goalItems = []) => {
        const goalItemsMap = new Map()
        goalItems.forEach((goalItem) => {
            if (goalItem.season_active === false) return
            // After modification delivered is undefined
            // Making sure we get a boolean value
            const delivered = goalItem.delivered === true
            const key = `${goalItem.product.id}-${goalItem.price}-${delivered}`
            const existingGoalItem = goalItemsMap.get(key)
            if (existingGoalItem) {
                goalItemsMap.set(key, {
                    ...existingGoalItem,
                    nbOfGoalItems: ++existingGoalItem.nbOfGoalItems
                })
            } else {
                goalItemsMap.set(key, {
                    productId: goalItem.product.id,
                    productName: goalItem.product.name,
                    price: goalItem.price,
                    imageUrl: findProduct(goalItem.product.id)?.image_url || '',
                    nbOfGoalItems: 1,
                    delivered
                })
            }
        })
        setLocalGoalItems(Array.from(goalItemsMap.values()))
    }

    if (loading) {
        return (
            <Spinner
                aria-label="loading-indicator"
                size="90"
                pageSpinner={true}
                fullscreen={true}
            />
        )
    }

    const displayFilteredGoalItems = ({ delivered = false }) => {
        const filteredGoalItems = localGoalItems.filter(
            (goalItem) =>
                goalItem.delivered === delivered ||
                (goalItem.delivered === undefined && !delivered)
        )
        if (filteredGoalItems.length === 0) return null
        const className =
            'client-goal-items-banner' +
            (delivered ? '--delivered' : finisher ? '--finisher' : '')
        return (
            <div>
                <div className={className}>
                    {delivered
                        ? PackageIcon({ color: colors.lightGreen })
                        : TruckDeliveryIcon({
                              color: finisher ? colors.purple : colors.grey
                          })}
                </div>
                <div className="goal-items-grid">
                    {filteredGoalItems.map((goalItem, index) => {
                        return (
                            <div
                                key={index}
                                className={`goal-item${
                                    index === filteredGoalItems.length - 1 &&
                                    filteredGoalItems.length % 2 !== 0
                                        ? '-center'
                                        : ''
                                }`}
                                onClick={
                                    canUpdatePackage && !delivered
                                        ? modifyHandler
                                        : null
                                }
                            >
                                <ClientGoalItem
                                    goalItem={goalItem}
                                    color={
                                        delivered
                                            ? colors.lightGreen
                                            : finisher
                                            ? colors.purple
                                            : colors.primary
                                    }
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    return (
        <div className="village-client-packet-parent">
            <Card shadow className="no-lateral-padding">
                <div className="village-client-details-padding">
                    <div>
                        <p className="village-client-packet-header">
                            {t('clientDetails.packetsTitle')}
                        </p>
                    </div>
                    {localGoalItems.length > 0 ? (
                        <>
                            {displayFilteredGoalItems({ delivered: false })}
                            {displayFilteredGoalItems({ delivered: true })}
                        </>
                    ) : (
                        <EmptyList message={t('notAvailable.packets')} />
                    )}
                </div>
            </Card>
        </div>
    )
}

VillageClientPacket.propTypes = {
    goalItems: PropTypes.array,
    modifyHandler: PropTypes.func,
    allowModification: PropTypes.bool,
    finisher: PropTypes.bool
}
