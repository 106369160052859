import React from 'react'
import { amountFormatter } from 'helpers'
import './cardInventorySummary.scss'

type InventorySummaryOptions = {
    amount: number
    title: string
    numberOfCards: number
    color: string
    country: string
}

export const CardInventorySummary = ({
    amount,
    title,
    numberOfCards,
    color,
    country
}: InventorySummaryOptions) => {
    return (
        <div className="summary-container" aria-label="card-inventory-item">
            <h3
                className="summary-title"
                aria-label="card-inventory-summary-title"
            >
                {title.toUpperCase()}
            </h3>
            <div className="summary-numbers-container">
                <div
                    className="summary-nb-cards"
                    aria-label="card-inventory-summary-number-of-cards"
                    style={{ backgroundColor: color }}
                >
                    <p>{numberOfCards}</p>
                </div>
                <div
                    className="summary-amount"
                    aria-label="card-inventory-summary-total-amount"
                >
                    <p>{amountFormatter(amount, country)}</p>
                </div>
            </div>
        </div>
    )
}
