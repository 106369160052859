// See: src/scss/abstract/_variables.scss
export const colors = {
    primary: '#16A34A',
    white: '#FFFFFF',
    red: '#E85B5B',
    grey: '#979797',
    lightGrey: '#D9D9D9',
    purple: '#9263D0',
    lightGreen: '#81D219',
    lighterGreen: '#F1FDF5',
    black: '#000',
    blue: '#2C70E3',
    lightBlue: '#EAF1FC',
    yellow: '#FFCD52',
    green: '#8AD1A4',
    limeGreen: '#D0EDDB'
}
