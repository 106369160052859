import React from 'react'
import './textInput.css'
import { TextTypes } from './textTypes'

export const TextInput: React.FC<TextTypes> = ({
    icon,
    type = 'text',
    validation,
    errorValidation,
    className,
    ...props
}) => {
    const inputError = errorValidation ? 'generic--error-border' : ''
    return (
        <span className="textfield--parent">
            <input
                className={`textfield-input ${className} ${inputError}`}
                {...props}
                type={type}
                maxLength={props.maxLength}
            />
            {validation && <div className="textfield-input--icon">{icon}</div>}
        </span>
    )
}
