import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useBindDispatch } from 'hooks'
import {
    Container,
    DownloadableCard,
    Spinner,
    TopBar,
    SearchableTab
} from 'components'

import { colors } from 'styles/colors'
import { getConnectUser, searchBy, VillageType } from 'helpers'
import { NetworkBarSvgs, PhoneIcon, VeIcon } from 'assets'
import { VeType } from 'storeTypes/vesTypes'

export const FcVillage = () => {
    const { VillagesIcon } = NetworkBarSvgs
    const { t } = useTranslation()
    const fcData = useAppSelector((state) => state.fcMetrics.data)
    const {
        ves,
        villages,
        loading: loadingVes
    } = useAppSelector((state) => state.ves)
    const { staff_code: staffCode } = getConnectUser()
    const { fetchVesHandler, fcMetricsHandler } = useBindDispatch()

    const PhoneLink = ({ phone }: { phone: string }) => {
        if (phone) {
            return (
                <a href={`tel:${phone}`} aria-label="phone-link">
                    <PhoneIcon
                        width="30"
                        height="30"
                        color={colors.blue}
                        fillColor={colors.blue}
                    />
                </a>
            )
        }

        return (
            <PhoneIcon
                width="30"
                height="30"
                color={colors.grey}
                fillColor={colors.grey}
            />
        )
    }

    useEffect(() => {
        if (!fcData && staffCode) {
            fcMetricsHandler(staffCode)
        }
    }, [fcData, staffCode])

    useEffect(() => {
        if (fcData?.fc_info?.zone_code) {
            fetchVesHandler(fcData.fc_info.zone_code)
        }
    }, [fcData])

    if (!staffCode || !fcData || loadingVes) {
        return <Spinner size="90" pageSpinner={true} />
    }

    return (
        <div>
            <Container>
                <TopBar iconColor={colors.black} transparentTopBar>
                    {t('zone')} {fcData.fc_info.zone_name}
                </TopBar>
            </Container>
            <div className="page-wrapper">
                <SearchableTab
                    tabs={[
                        {
                            text: t('village.villages'),
                            placeholderText: t(
                                'village.villageSearchPlaceholder'
                            ),
                            noResultsText: t('village.villageNoResults'),
                            icon: (
                                <VillagesIcon
                                    width="21"
                                    height="21"
                                    color={colors.grey}
                                />
                            ),
                            extraClass: 'green',
                            items: villages,
                            renderItem: (village) => (
                                <DownloadableCard
                                    key={village.code}
                                    headerColor={colors.lighterGreen}
                                    borderBottomColor={colors.primary}
                                    icon={
                                        <VillagesIcon
                                            width="35"
                                            height="35"
                                            color={colors.primary}
                                        />
                                    }
                                    title={village.name || ''}
                                    subtitles={(
                                        (village as VillageType).veNames || []
                                    ).concat([village.code || ''])}
                                />
                            ),
                            searchItems: (villages, query) =>
                                searchBy(
                                    villages as VillageType[],
                                    ['name', 'code', 'veNames'],
                                    query
                                )
                        },
                        {
                            text: t('village.ves'),
                            placeholderText: t('village.veSearchPlaceholder'),
                            noResultsText: t('village.veNoResults'),
                            icon: (
                                <VeIcon
                                    width="21"
                                    height="21"
                                    color={colors.grey}
                                />
                            ),
                            extraClass: 'blue',
                            items: ves,
                            renderItem: (ve) => {
                                const phone = (ve as VeType).phone_flotte
                                const subtitles = (
                                    ve as VeType
                                ).current_villages
                                    ?.split(',')
                                    .map((village) => village.trim())

                                return (
                                    <DownloadableCard
                                        key={ve.code}
                                        headerColor={colors.lightBlue}
                                        borderBottomColor={colors.blue}
                                        icon={
                                            <VeIcon
                                                width="35"
                                                height="35"
                                                color={colors.blue}
                                            />
                                        }
                                        endIcon={<PhoneLink phone={phone} />}
                                        title={(ve as VeType).name}
                                        subtitles={subtitles}
                                    />
                                )
                            },
                            searchItems: (ves, query) =>
                                searchBy(
                                    ves as VeType[],
                                    ['name', 'code', 'current_villages'],
                                    query
                                )
                        }
                    ]}
                />
            </div>
        </div>
    )
}
