import React from 'react'
import { Navigate, Outlet, Route } from 'react-router-dom'
import { FcHome } from 'fc/pages/home/FcHome'
import { FcProfile } from 'fc/pages/profile/FcProfile'
import { FcVillage } from 'fc/pages/village/FcVillage'

const ConnectRoutes = () => {
    return (
        <>
            <Route path="/" element={<Navigate replace to="/fc/home" />} />
            <Route path="/fc" element={<Outlet />}>
                <Route path="home" element={<FcHome />} />
                <Route path="profile" element={<FcProfile />} />
                <Route path="village" element={<FcVillage />} />
            </Route>
        </>
    )
}

export default ConnectRoutes
