export function searchBy<T>(
    items: T[],
    attributes: (keyof T)[],
    query: string
) {
    return items.filter((item) =>
        attributes.some((attribute) => {
            const value = String(item[attribute])
            const terms = query.split(/\s+/)

            return terms.every((term) =>
                value.toLowerCase().includes(term.toLowerCase())
            )
        })
    )
}
