import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
    Container,
    Row,
    Col,
    TopBar,
    Card,
    CardClientPayment,
    CardNameAbr,
    RegisteredDeposit,
    Spinner,
    TimedAlert
} from '../../components'
import { useBindDispatch, useGetStorage, useScreenSize } from '../../hooks'
import {
    amountFormatter,
    sortHandler,
    constants,
    getConnectUser
} from '../../helpers'
import './ranking.css'
import { CSSTransition } from 'react-transition-group'
import { medal } from '../../assets'
import { findPermission } from '../../helpers/findPermission'
const { MOBILE_SIZE, SORT_TYPE, SORT_DIRECTION, RANKING_OPTIONS } = constants

export const Ranking = () => {
    const { t } = useTranslation()
    const screenSize = useScreenSize()
    const [animate, setAnimate] = useState(false)
    const [animationList, setAnimationList] = useState(false)
    const {
        enrollment_progress_high_threshold: enrollmentHigh,
        enrollment_progress_medium_threshold: enrollmentMedium
    } = JSON.parse(localStorage.getItem('country_config'))
    const nodeRef = useRef(null)
    const {
        zone_code: zoneCode,
        username: userCode,
        country
    } = getConnectUser()
    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)
    const { ranking_sort: rankingSort } = useGetStorage('country_config')
    const {
        veRankingData,
        loading,
        preFetchLoading,
        error: rankingPageError
    } = useSelector((state) => state.veRankingList)
    const { veRankingHandler } = useBindDispatch()

    const isMobile = screenSize.screenWidth <= MOBILE_SIZE

    const canViewFinishers = findPermission(
        userPermissions,
        constants.VIEW_FINISHERS
    )

    useEffect(() => {
        setAnimate(true)
        if (veRankingData.length < 1 && !preFetchLoading) {
            veRankingHandler({ zoneCode })
        }
        // set page title
        document.title = t('ranking.pageTitle')
    }, [])

    const pageTitle = (veData) => {
        return `${t('ranking.rankingTitle', { number: veData?.length })}`
    }

    const getVeRank = (veDataRanked, veCode) => {
        // find the logged in ve by index
        const rank = veDataRanked.findIndex((ve) => ve.code === veCode)
        // return index
        return rank + 1
    }

    const processRankings = (rankings) => {
        if (rankingSort === RANKING_OPTIONS.NUMBER_OF_ENROLLMENTS) {
            return sortHandler(
                [...rankings],
                've_clients_enrolled',
                SORT_TYPE.ALPHA_NUMERIC,
                SORT_DIRECTION.DESCENDING
            )
        }
        if (rankingSort === RANKING_OPTIONS.ENROLLMENT_PROGRESS) {
            const veMetTarget = rankings.filter(
                (ve) =>
                    ve.ve_target_clients_enrolled &&
                    parseInt(ve.ve_clients_enrolled) >
                        parseInt(ve.ve_target_clients_enrolled)
            )
            const veWithTarget = rankings.filter(
                (ve) =>
                    ve.ve_target_clients_enrolled &&
                    parseInt(ve.ve_target_clients_enrolled) >
                        parseInt(ve.ve_clients_enrolled)
            )
            let veWithoutTarget = rankings.filter(
                (ve) => !ve.ve_target_clients_enrolled
            )
            // filter VEs without target by number of enrollments on the bottom of the page
            veWithoutTarget = sortHandler(
                [...veWithoutTarget],
                've_clients_enrolled',
                SORT_TYPE.ALPHA_NUMERIC,
                SORT_DIRECTION.DESCENDING
            )
            const groupedData = [
                ...veMetTarget,
                ...veWithTarget,
                ...veWithoutTarget
            ]
            const sortedRanking = groupedData.sort((a, b) => {
                const percentageA = (
                    (parseFloat(a?.ve_clients_enrolled) /
                        parseFloat(a?.ve_target_clients_enrolled)) *
                    100
                ).toPrecision(3)
                const percentageB = (
                    (parseFloat(b.ve_clients_enrolled) /
                        parseFloat(b.ve_target_clients_enrolled)) *
                    100
                ).toPrecision(3)
                return percentageB - percentageA
            })
            return sortedRanking
        }
    }

    const rankingPageRender = (veData) => {
        const loggedInUserData = []
        const otherUsersData = []
        const sortedVeData = processRankings(veData)
        sortedVeData.map(
            (
                {
                    name,
                    current_villages: currentVillage,
                    ve_target_clients_enrolled: veTargetClientsEnrolled,
                    ve_clients_enrolled: veClientsEnrolled,
                    target_finishers_this_season: targetFinishersThisSeason,
                    total_sales_this_season: totalSalesThisSeason,
                    sales_target_this_season: salesTargetThisSeason,
                    code,
                    finishers_this_season: finishersThisSeason
                },
                idx
            ) => {
                idx += 2
                veClientsEnrolled = parseFloat(veClientsEnrolled) || 0
                targetFinishersThisSeason =
                    parseFloat(targetFinishersThisSeason) || 0
                finishersThisSeason = parseFloat(finishersThisSeason) || 0
                totalSalesThisSeason = parseFloat(totalSalesThisSeason) || 0
                salesTargetThisSeason = parseFloat(salesTargetThisSeason) || 0

                const enrolledPercentage = (
                    (parseFloat(veClientsEnrolled) /
                        parseFloat(veTargetClientsEnrolled)) *
                    100
                ).toPrecision(3)

                const finisherPercentage = (
                    (finishersThisSeason / targetFinishersThisSeason) *
                    100
                ).toPrecision(3)

                const salesPercentage = (
                    (totalSalesThisSeason / salesTargetThisSeason) *
                    100
                ).toPrecision(3)
                if (code === userCode) {
                    const rank = getVeRank(sortedVeData, userCode)
                    return loggedInUserData.push(
                        <Card
                            shadow={false}
                            className="client-contact-card"
                            data-testid="logged-user-ranking-card"
                            key={userCode}
                        >
                            {isMobile && (
                                <div className="rank-parent">{medal(rank)}</div>
                            )}
                            <CardClientPayment clientStatus="Interéssé">
                                <CardNameAbr
                                    subHeader={currentVillage.split(' ')[0]}
                                    className="ranking-village"
                                >
                                    {name}
                                </CardNameAbr>
                                <RegisteredDeposit
                                    percentage={
                                        parseFloat(enrolledPercentage) || 0
                                    }
                                    target={veTargetClientsEnrolled}
                                    result={veClientsEnrolled}
                                    className="ve-ranking--progress-bar"
                                    enrollmentPercentageRange={{
                                        high: enrollmentHigh,
                                        low: enrollmentMedium
                                    }}
                                >
                                    {t('registered')}
                                </RegisteredDeposit>
                                {canViewFinishers && (
                                    <RegisteredDeposit
                                        percentage={
                                            parseFloat(finisherPercentage) || 0
                                        }
                                        target={targetFinishersThisSeason}
                                        result={finishersThisSeason}
                                        className="ve-ranking--progress-bar"
                                        enrollmentPercentageRange={{
                                            high: enrollmentHigh,
                                            low: enrollmentMedium
                                        }}
                                    >
                                        {t('ranking.finisher')}
                                    </RegisteredDeposit>
                                )}
                                {salesTargetThisSeason ? (
                                    <RegisteredDeposit
                                        percentage={
                                            parseFloat(salesPercentage) || 0
                                        }
                                        target={amountFormatter(
                                            salesTargetThisSeason,
                                            country
                                        )}
                                        result={amountFormatter(
                                            totalSalesThisSeason,
                                            country
                                        )}
                                        className="ve-ranking--progress-bar"
                                    >
                                        {t('ranking.revenue')}
                                    </RegisteredDeposit>
                                ) : (
                                    <RegisteredDeposit
                                        percentage={
                                            parseFloat(salesPercentage) || 0
                                        }
                                        target={
                                            salesTargetThisSeason &&
                                            amountFormatter(
                                                salesTargetThisSeason,
                                                country
                                            )
                                        }
                                        result={amountFormatter(
                                            totalSalesThisSeason,
                                            country
                                        )}
                                        className="ve-ranking--progress-bar"
                                    >
                                        {t('ranking.revenue')}
                                    </RegisteredDeposit>
                                )}
                            </CardClientPayment>
                        </Card>
                    )
                }

                return otherUsersData.push(
                    <Card
                        shadow={false}
                        key={code}
                        className={
                            animationList
                                ? `client-contact-card animate-show-${idx} animate-showed-${idx}`
                                : `animate-show-${idx}`
                        }
                        data-testid="ranking-card"
                    >
                        <CardClientPayment clientStatus="Interéssé">
                            <CardNameAbr
                                subHeader={currentVillage?.split(' ')[0]}
                                className="ranking-village"
                            >
                                {name}
                            </CardNameAbr>
                            <RegisteredDeposit
                                percentage={parseFloat(enrolledPercentage) || 0}
                                target={veTargetClientsEnrolled}
                                result={veClientsEnrolled}
                                className="ve-ranking--progress-bar"
                                enrollmentPercentageRange={{
                                    high: enrollmentHigh,
                                    low: enrollmentMedium
                                }}
                            >
                                {t('registered')}
                            </RegisteredDeposit>
                        </CardClientPayment>
                    </Card>
                )
            }
        )

        return (
            <div className="page-wrapper ve-ranking--parent" ref={nodeRef}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="ve-ranking--behind-section">
                                <h2 className="header-welcome ve-ranking--header-section">
                                    {t('ranking.myRankingTitle')}
                                </h2>

                                <div
                                    className={
                                        animationList
                                            ? 've-ranking--behind-body animate-show-2 animate-showed-2'
                                            : 'animate-show-2'
                                    }
                                >
                                    {loggedInUserData}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="ve-ranking--behind-section">
                                <h2 className="header-welcome ve-ranking--header-section">
                                    {pageTitle(otherUsersData)}
                                </h2>

                                <div className="ve-ranking--behind-body ve-ranking--ranking-alignment">
                                    {otherUsersData}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    const rankingPage = loading ? (
        <Spinner size={'90'} pageSpinner={true} />
    ) : rankingPageError ? (
        <TimedAlert type="floating" status="error">
            {rankingPageError}
        </TimedAlert>
    ) : (
        rankingPageRender(veRankingData)
    )

    return (
        <>
            <div className="header-bar" id="header-bar">
                <Container>
                    <TopBar back>{t('ranking.myRankingHeader')}</TopBar>
                </Container>
            </div>
            <CSSTransition
                unmountOnExit
                timeout={constants.ANIMATION_TIMEOUT}
                in={animate}
                classNames="generic"
                appear
                onEnter={() => setAnimationList(true)}
                nodeRef={nodeRef}
            >
                {rankingPage}
            </CSSTransition>
        </>
    )
}
