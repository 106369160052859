import React from 'react'
import { useTranslation } from 'react-i18next'

import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import {
    Col,
    Links,
    Row,
    Card,
    Spinner,
    RemainDepositBadge
} from 'components'
import { amountFormatter, constants } from 'helpers'
import { useAppSelector } from 'hooks'
import { colors } from 'styles/colors'
import { FarmerIcon, MyAgroLogo, VisitedHandshakeIcon, cardPaymentIcon, mobileMoneyPaymentIcon, TrophyIcon, NotAvailableIcon } from 'assets'
import { MetricsProgress } from 'components/metricsProgress/MetricsProgress'
import { DepositType } from 'storeTypes'
import CircularProgressBar from 'components/circularProgressBar/CircularProgressBar'

interface VeMetricsProps {
    animationList: boolean
    hideBorder?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ve?: any
    connectUser: { country: string; veVillages: { sf_id: string }[] }
    canViewDeposits: boolean
    canViewDepositGap: boolean
    cardClickHandler: (filterOptions: string[], clientTab: number) => void
    veDeposit?: DepositType
    depositHandler?: () => void
}

const isTargetValid = (value: number | null | undefined) => value !== 0 && value

export const VeMetrics = ({
    animationList,
    ve,
    connectUser,
    canViewDeposits,
    canViewDepositGap,
    cardClickHandler,
    veDeposit,
    depositHandler
}: VeMetricsProps) => {
    const { loading } = useAppSelector(
        (store) => store.interactions[connectUser.veVillages[0]?.sf_id] || {}
    )
    const countryConfig = JSON.parse(
        localStorage.getItem('country_config') || '{}'
    )
    const progressBarRange = {
        low: countryConfig?.sales_progress_medium_threshold,
        high: countryConfig?.sales_progress_high_threshold
    }

    const { t } = useTranslation()
    const { VILLAGE_PAGE_TABS } = constants

    const {
        target_monthly_sales: targetMonthlySales,
        sales_this_month: salesThisMonth,
        target_clients_active_this_month: targetClientsActiveThisMonth,
        clients_enrolled_this_month: clientsEnrolledThisMonth,
        target_monthly_enrolled: targetMonthlyEnrolled,
        clients_active_this_month: clientsActiveThisMonth,
        target_weekly_visited: targetWeeklyVisited,
        visits_this_week: visitsThisWeek,
        total_deposit: totalDeposit,
        gap: remainingDeposit,
        total_clients_registered_this_week: totalClientsRegisteredThisWeek,
        target_clients_registered_this_week: targetClientsRegisteredThisWeek,
        total_clients_isp: packageNoPayment,
        total_clients_psi: paymentNoPackage,
        total_payments_mobile_this_month: mobilePaymentThisMonth,
        total_payments_card_this_month: cardPaymentThisMonth
    } = ve || {
        target_monthly_sales: '',
        sales_this_month: '',
        target_clients_active_this_month: '',
        clients_enrolled_this_month: '',
        target_monthly_enrolled: '',
        clients_active_this_month: '',
        target_weekly_visited: '',
        visits_this_week: '',
        total_deposit: '',
        gap: '',
        total_payments_mobile_this_month: '',
        total_payments_card_this_month: ''
    }
    const hideBorder = !canViewDeposits && !canViewDepositGap

    const remainingSales =
        parseFloat(targetMonthlySales) - parseFloat(salesThisMonth)
    const isTargetMonthlySalesValid = isTargetValid(targetMonthlySales)
    const totalSales = mobilePaymentThisMonth + cardPaymentThisMonth
    const salesPercentage = targetMonthlySales ? (totalSales / targetMonthlySales) * 100 : 0
    const baseSales = targetMonthlySales != null && targetMonthlySales < totalSales ? totalSales : targetMonthlySales
    const mobilePercentage = (mobilePaymentThisMonth / baseSales) * 100
    const cardPercentage = (cardPaymentThisMonth / baseSales) * 100
    const actualSalesPercentage = totalSales / baseSales * 100
    const incompleteAmount = targetMonthlySales - (mobilePaymentThisMonth + cardPaymentThisMonth)
    const totalPercentage = mobilePercentage + cardPercentage

    if (loading) {
        return (
            <div className="target-list--spinner-parent">
                <Spinner
                    data-testid="loading-indicator"
                    size="50"
                    pageSpinner={false}
                    fullscreen={false}
                />
            </div>
        )
    }

    return (
        <Row>
            <Col md={12} className="profile-card-session">
                <div>
                    <div className="header">
                        <div className="header-icon">{FarmerIcon}</div>
                        <p className="session-header header-month">
                            {t('home.thisMonth')}
                        </p>
                        <h2 className="session-header header-card-text">
                            {t('profile.myFarmers')}
                        </h2>
                    </div>
                    <Row className="client-row weekly-objectives">
                        <Col className="client-card-parent">
                            <Card
                                className={
                                    animationList
                                        ? 'client-card animate-show-3 animate-showed-3'
                                        : 'client-card animate-show-3'
                                }
                                shadow
                                onClick={() =>
                                    cardClickHandler(
                                        [t('village.active')],
                                        VILLAGE_PAGE_TABS.CURRENT_CLIENT_TAB
                                    )
                                }
                                data-testid="client-active-card"
                                id="active-client-card"
                            >
                                <MetricsProgress
                                    current={clientsActiveThisMonth}
                                    target={targetClientsActiveThisMonth}
                                    ranges={progressBarRange}
                                />
                                <p className="client-card--header">
                                    {t('profile.activeCustomers')}
                                </p>
                            </Card>
                        </Col>
                        <Col className="client-card-parent">
                            <Card
                                className={
                                    animationList
                                        ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                        : 'client-card second-client-card animate-show-4'
                                }
                                shadow
                                onClick={() =>
                                    cardClickHandler(
                                        [
                                            t('village.active'),
                                            t('village.inactive')
                                        ],
                                        VILLAGE_PAGE_TABS.CURRENT_CLIENT_TAB
                                    )
                                }
                                data-testid="client-enrolled-card"
                                id="registered-client-card"
                            >
                                <MetricsProgress
                                    current={clientsEnrolledThisMonth}
                                    target={targetMonthlyEnrolled}
                                    ranges={progressBarRange}
                                />
                                <p className="client-card--header">
                                    {t('profile.toRegister')}
                                </p>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="client-row weekly-objectives">
                        <Col className="client-card-parent">
                            <Card
                                className={
                                    animationList
                                        ? 'client-card animate-show-3 animate-showed-3'
                                        : 'client-card animate-show-3'
                                }
                                onClick={() =>
                                    cardClickHandler(
                                        [t('village.packageNoPayment')],
                                        VILLAGE_PAGE_TABS.CURRENT_CLIENT_TAB
                                    )
                                }
                                shadow
                                aria-label="package-no-payment-card"
                            >
                                <MetricsProgress
                                    current={packageNoPayment}
                                    target={0}
                                    extraClasses="client-number--highlight"
                                />
                                <p className="client-card--header">
                                    {t('profile.packageNoPayment')}
                                </p>
                            </Card>
                        </Col>
                        <Col className="client-card-parent">
                            <Card
                                className={
                                    animationList
                                        ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                        : 'client-card second-client-card animate-show-4'
                                }
                                onClick={() =>
                                    cardClickHandler(
                                        [t('village.paymentNoPackage')],
                                        VILLAGE_PAGE_TABS.PREVIOUS_CLIENT_TAB
                                    )
                                }
                                shadow
                                aria-label="payment-no-package-card"
                            >
                                <MetricsProgress
                                    current={paymentNoPackage}
                                    target={0}
                                    extraClasses="client-number--highlight"
                                />
                                <p className="client-card--header">
                                    {t('profile.paymentNoPackage')}
                                </p>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col md={12} className="profile-card-session">
                <div
                    className={
                        animationList
                            ? 'performance-card-parent animate-show-2 animate-showed-2'
                            : 'animate-show-2'
                    }
                >
                    <div
                        className="card-sale-parent"
                        style={{
                            border: hideBorder ? '' : 'none'
                        }}
                    >
                        <div className="ve-card-sale">
                            <div className="ve-card-sale--icon">
                                {mobileMoneyPaymentIcon({ backgroundColor: '' })}
                                {cardPaymentIcon({ backgroundColor: '' })}
                            </div>
                            <div className="ve-card-sale--header">
                                <p className="ve-card-sale--header-month">{t('home.thisMonth')}</p>
                                <h2 className="ve-card-sale--header-title">{t('home.sales')}{' '}</h2>
                            </div>
                            <div className="ve-card-sale--progressbar">
                            {targetMonthlySales > 0 && (
                                <div
                                    className="ve-card-sale--total-payment"
                                    style={{
                                        color: totalSales >= targetMonthlySales ? '#16A34A' : '#979797'
                                    }}>
                                    {TrophyIcon({
                                        width: '20',
                                        height: '20',
                                        color: totalSales >= targetMonthlySales ? '#16A34A' : '#979797'
                                    })}
                                    {amountFormatter(targetMonthlySales)} CFA
                                </div>
                            )}
                                <Splide options={{
                                    perPage: 1,
                                    pagination: true,
                                    arrows: false
                                }}>
                                    <SplideSlide>
                                        <div className="ve-card-sale--progressbar">
                                            <div className="ve-card-sale--sales-title">
                                                {t('profile.sales')}
                                            </div>
                                            {actualSalesPercentage <= 0 || totalSales === 0 ? (
                                                <div className="ve-card-sale--not-available">
                                                    <NotAvailableIcon width="105" height="105" />
                                                </div>
                                            ) : actualSalesPercentage < 30 ? (
                                                <CircularProgressBar key={salesThisMonth}
                                                    percentage={actualSalesPercentage}
                                                    incompleteLabel={amountFormatter(incompleteAmount)}
                                                    showMilestones={false}
                                                    gaugeColor={salesPercentage === 100 ? colors.primary : colors.green}
                                                />
                                            ) : actualSalesPercentage < 40 ? (
                                                <CircularProgressBar
                                                    key={salesThisMonth}
                                                    percentage={actualSalesPercentage}
                                                    incompleteLabel={amountFormatter(incompleteAmount)}
                                                    centerLabel={
                                                        <div style={{ color: '#231F20' }}>
                                                            {amountFormatter(totalSales)}
                                                        </div>
                                                    }
                                                    showMilestones={false}
                                                    gaugeColor={salesPercentage === 100 ? colors.primary : colors.green}
                                                />
                                            ) : (
                                                <CircularProgressBar
                                                    key={salesThisMonth}
                                                    percentage={actualSalesPercentage}
                                                    incompleteLabel={amountFormatter(incompleteAmount)}
                                                    centerIconHeight={20}
                                                    centerLabel={
                                                        <div>
                                                            {mobileMoneyPaymentIcon({ backgroundColor: '', height: '24', width: '24' })}
                                                            {cardPaymentIcon({ backgroundColor: '', height: '24', width: '24' })}
                                                            <div style={{ color: '#231F20' }}>
                                                                {amountFormatter(totalSales)}
                                                            </div>
                                                        </div>
                                                    }
                                                    showMilestones={false}
                                                    gaugeColor={salesPercentage === 100 ? colors.primary : colors.green}
                                                    aria-label="circular-progress-bar"
                                                />
                                            )}

                                        </div>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <div className="ve-card-sale--progressbar">
                                            <div className="ve-card-sale--sales-title" aria-label='salesThisMonth'>
                                                {amountFormatter(salesThisMonth)} CFA
                                            </div>
                                            {actualSalesPercentage <= 0 || totalSales === 0 ? (
                                                <div className="ve-card-sale--not-available">
                                                    <NotAvailableIcon width="105" height="105" />
                                                </div>
                                            ) : (
                                                <CircularProgressBar
                                                key={salesThisMonth}
                                                percentage={totalPercentage}
                                                segments={[
                                                        {
                                                            percentage: cardPercentage,
                                                            color: colors.green,
                                                            label: cardPercentage >= 30 ? (
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {cardPaymentIcon({ backgroundColor: '', height: '24', width: '24' })}
                                                                    { amountFormatter(cardPaymentThisMonth) }
                                                                </div>
                                                            ) : null
                                                        },
                                                        {
                                                            percentage: mobilePercentage,
                                                            color: colors.limeGreen,
                                                            label: mobilePercentage >= 30 ? (
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {mobileMoneyPaymentIcon({ backgroundColor: '', height: '24', width: '24' })}
                                                                    { amountFormatter(mobilePaymentThisMonth) }
                                                                </div>
                                                            ) : null
                                                        }
                                                    ]}
                                                    incompleteLabel={amountFormatter(incompleteAmount)}
                                                    showMilestones={false}
                                                    aria-label="circular-progress-bar"
                                                />
                                            )}

                                        </div>
                                    </SplideSlide>
                                </Splide>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="sales-deposit-parent">
                        {isTargetMonthlySalesValid && (
                            <div className="sales-deposit">
                                <p>{t('home.remainingSales')}</p>
                                <p data-testid="remaining-sales">
                                    {amountFormatter(
                                        remainingSales > 0 && remainingSales,
                                        connectUser.country
                                    )}{' '}
                                </p>
                            </div>
                        )}
                        {canViewDeposits && (
                            <div className="sales-deposit">
                                <Links
                                    className="deposits-link"
                                    to={`deposits?ve_name=${ve.name}`}
                                    id="deposits-link"
                                    role="deposit-link"
                                >
                                    {t('home.deposit')}
                                </Links>
                                <p>
                                    {amountFormatter(
                                        totalDeposit > 0 && totalDeposit,
                                        connectUser.country
                                    )}{' '}
                                </p>
                            </div>
                        )}
                        {canViewDepositGap && (
                            <div
                                className="sales-deposit"
                                title="sales-deposit"
                            >
                                <p>{t('home.remainingDeposit')}</p>
                                <p>
                                    {amountFormatter(
                                        remainingDeposit,
                                        connectUser.country
                                    )}{' '}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </Col>
            <Col md={12} className="profile-card-session">
                <div>
                    <div className="header">
                        <div className="visit-icon">
                            {VisitedHandshakeIcon({})}
                        </div>
                        <p className="session-header header-month">
                            {t('home.thisWeek')}
                        </p>
                        <h2 className="session-header header-card-text">
                            {t('profile.myVisits')}
                        </h2>
                    </div>
                    <Row className="client-row weekly-objectives">
                        {
                            <Col className="client-card-parent">
                                <Card
                                    className={
                                        animationList
                                            ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                            : 'client-card second-client-card animate-show-4'
                                    }
                                    shadow
                                    onClick={() =>
                                        cardClickHandler(
                                            [t('village.visitedThisWeek')],
                                            VILLAGE_PAGE_TABS.PREVIOUS_CLIENT_TAB
                                        )
                                    }
                                    data-testid="visited-client-card"
                                    id="visited-client-card"
                                >
                                    <div className="client-number">
                                        <MetricsProgress
                                            current={visitsThisWeek}
                                            target={targetWeeklyVisited}
                                            ranges={progressBarRange}
                                        />
                                    </div>
                                    <p className="client-card--header">
                                        {t('profile.potentialClientsVisited')}
                                    </p>
                                </Card>
                            </Col>
                        }
                        {
                            <Col className="client-card-parent">
                                <Card
                                    className={
                                        animationList
                                            ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                            : 'client-card second-client-card animate-show-4'
                                    }
                                    shadow
                                    onClick={() =>
                                        cardClickHandler(
                                            [t('village.active')],
                                            VILLAGE_PAGE_TABS.PREVIOUS_CLIENT_TAB
                                        )
                                    }
                                    data-testid="registered-client-card"
                                    id="registered-client-card"
                                >
                                    <div className="client-number">
                                        <MetricsProgress
                                            current={
                                                totalClientsRegisteredThisWeek
                                            }
                                            target={
                                                targetClientsRegisteredThisWeek
                                            }
                                            ranges={progressBarRange}
                                        />
                                    </div>
                                    <p className="client-card--header">
                                        {t('profile.newlyVisited')}
                                    </p>
                                </Card>
                            </Col>
                        }
                    </Row>
                </div>
            </Col>

            <Col md={12} className="profile-card-session">
                <Row>
                    <div className="header">
                        <div className="visit-icon">
                            <MyAgroLogo size="25" color="#231F20" />
                        </div>
                        <h2 className="session-header header-card-text">
                            {t('home.myDeposits')}
                        </h2>
                    </div>
                </Row>
                <Row>
                    <div className="profile--card-row">
                        <p className="profile--card-deposited-text">
                            {amountFormatter(
                                veDeposit?.total_deposit,
                                connectUser.country
                            )}
                        </p>
                    </div>
                </Row>
                <Row>
                    <div
                        className="profile--card-deposits"
                        onClick={depositHandler}
                    >
                        <RemainDepositBadge
                            totalRemainingDeposit={amountFormatter(
                                veDeposit?.gap,
                                connectUser.country
                            )}
                        />
                    </div>
                </Row>
            </Col>
        </Row>
    )
}
