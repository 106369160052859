import { amountFormatter } from 'helpers'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
    TransactionKind,
    TransactionPayloadType,
    TransactionSource
} from 'storeTypes'
import { PaymentSourceIcons } from './PaymentSourceIcons'
import { getTransactionTypeSettings } from './helpers/getTransactionTypeSettings'

export const PaymentItem: FC<{
    transaction: TransactionPayloadType
    country: string
}> = ({ transaction, country }) => {
    const { t } = useTranslation()

    const {
        type,
        amount,
        voucher_code: voucherCode,
        source,
        memo,
        matchingClientCode
    } = transaction

    const amountColor = amount && amount < 0 ? 'deducted-amount' : 'amount'

    const memoByTransactionType: Partial<Record<TransactionKind, string>> = {
        [TransactionKind.DELIVERY]: t('clientDetails.delivery'),
        [TransactionKind.AWARD]: `${t('clientDetails.award')} ${memo ?? ''}`,
        [TransactionKind.AWARD_REVERSAL]: `${t('clientDetails.award')}${
            memo
                ? ` ${memo.replace(/ ([0-9]{4}-[0-9]{2}-[0-9]{2})/, '-$1')}`
                : '-R'
        }`
    }
    return (
        <div className="list--item">
            {type && (
                <div
                    aria-label={
                        getTransactionTypeSettings(type, amountColor).labelText
                    }
                >
                    {getTransactionTypeSettings(type, amountColor).icon}
                </div>
            )}
            <div>
                <p
                    className={`list--item--header payment--${
                        getTransactionTypeSettings(
                            type || TransactionKind.VOUCHER_PAYMENT,
                            amountColor
                        )?.amountColor
                    }`}
                    data-testid="amount"
                >
                    {amountFormatter(
                        amount,
                        country,
                        type === TransactionKind.PENDING
                    )}
                </p>
                <p className="list--item--sub-header">
                    {memoByTransactionType[type as TransactionKind] ||
                        `${
                            matchingClientCode
                                ? voucherCode?.split('-R')[0]
                                : voucherCode
                        }${matchingClientCode ? `-${matchingClientCode}` : ''}`}
                </p>
            </div>
            <PaymentSourceIcons
                type={
                    (type?.toUpperCase() as TransactionKind) ||
                    TransactionKind.VOUCHER_PAYMENT
                }
                source={
                    (source?.toUpperCase() as TransactionSource) ||
                    TransactionSource.ORANGE_MONEY
                }
            />
        </div>
    )
}
