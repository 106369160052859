/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
    unstable_HistoryRouter as HistoryRouter,
    Routes,
    Route
} from 'react-router-dom'
import { ProtectedRoutes } from './ProtectedRoutes'
import { NetworkNavbar } from './NetworkNavbar'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { Login } from '../pages'

import { customHistory } from './customHistory'
import { setTheme, getConnectUser } from 'helpers'
import FcConnectRoutes from 'fc/navigation/ConnectRoutes'
import veConnectRoutes from 've/navigation/ConnectRoutes'
import { useGetStorage } from 'hooks'
import * as Sentry from '@sentry/react'

export const AppConnectRoutes = () => {
    const [isLoginAuthenticated, setIsLoginAuthenticated] = useState(false)
    let user = getConnectUser()
    if (process.env.REACT_APP_MATOMO_URL) {
        const { trackPageView, pushInstruction } = useMatomo()
        let username
        useEffect(() => {
            const unlisten = customHistory.listen(() => {
                // As soon as the user is logged in we want to set the username as userID for analytics
                if (!user?.username) {
                    user = getConnectUser()
                }
                if (!user?.username) {
                    Sentry.captureMessage(
                        'Username undefined in AppConnectRoutes',
                        'error'
                    )
                } else {
                    if (username !== user?.username) {
                        username = user?.username
                        pushInstruction('setUserId', user.username)
                    }
                    const splittedPath =
                        customHistory.location.pathname.split('/')
                    const pageName = splittedPath[1]
                    // Ignore history change when navigating to village without an SFID
                    // such as /village/villageSFID
                    // Because every time we navigate to the village page there is an extra call
                    if (pageName !== 'village' || splittedPath.length > 1) {
                        trackPageView({
                            documentTitle:
                                customHistory.location.pathname.split('/')[1]
                        })
                    }
                }
            })

            return () => {
                unlisten()
            }
        }, [customHistory])
    }
    useEffect(() => {
        const theme = useGetStorage('connect_theme')
        if (theme) {
            setTheme(theme)
        }
    }, [])

    const staffRouter = {
        VE: () => veConnectRoutes(),
        FC: () => FcConnectRoutes()
    }

    return (
        <HistoryRouter history={customHistory}>
            {<NetworkNavbar />}
            <Routes>
                <Route path="/" element={<ProtectedRoutes />}>
                    {(user.role || isLoginAuthenticated) &&
                        staffRouter[user.role]()}
                </Route>
            </Routes>
            <Routes>
                <Route
                    path="login"
                    element={<Login authHandler={setIsLoginAuthenticated} />}
                />
            </Routes>
        </HistoryRouter>
    )
}
