import React, { useState } from 'react'

import './searchableTab.scss'

import { Search } from 'components/search/Search'
import { colors } from 'styles/colors'
import { VeType } from 'storeTypes/vesTypes'
import { NotAvailableIcon } from 'assets'
import { VillageType } from 'helpers'

export type SearchItemType = VeType | Partial<VillageType>

type TabType = {
    text: string
    placeholderText: string
    noResultsText: string
    icon: JSX.Element
    extraClass: string
    renderItem: (item: SearchItemType) => JSX.Element
    searchItems: (items: SearchItemType[], query: string) => SearchItemType[]
    items: SearchItemType[]
}

interface TabProps {
    tabs: TabType[]
}

export const SearchableTab = ({ tabs = [] }: TabProps) => {
    const [query, setQuery] = useState('')
    const [activeTab, setActiveTab] = useState(tabs[0])

    const onTabChangeHandler = (text: string) => {
        const tab = tabs.find((t) => t.text === text)
        setActiveTab(tab || tabs[0])
        setQuery('')
    }

    const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value)
    }

    if (!activeTab) {
        return null
    }

    const {
        renderItem,
        searchItems,
        items,
        placeholderText: placeholder,
        noResultsText
    } = activeTab
    const searchedItems = searchItems(items, query)

    return (
        <div className="tabs">
            <div className="tabs--buttons">
                {tabs.map((tab, index) => {
                    const classes = ['tabs--button']
                    const isActive = tab.text === activeTab.text

                    const tabQuery = isActive ? query : ''

                    if (isActive) {
                        classes.push('active')
                        classes.push(tab.extraClass)
                    }

                    return (
                        <span
                            key={index}
                            onClick={() => onTabChangeHandler(tab.text)}
                            className={classes.join(' ')}
                        >
                            {tab.icon} {tab.text} (
                            {tab.searchItems(tab.items, tabQuery).length})
                        </span>
                    )
                })}
            </div>
            <div className="tabs--content">
                <div className="tabs--search">
                    <Search
                        iconSize="30"
                        iconPos="left"
                        iconColor={colors.grey}
                        style="compact"
                        onChange={onSearchHandler}
                        value={query}
                        placeholder={placeholder || ''}
                    />
                </div>
                <div className="tabs--items">
                    {searchedItems.length === 0 && (
                        <div className="tabs--empty">
                            <NotAvailableIcon />
                            <div className="tabs--empty-text">
                                <p>{noResultsText}</p>
                            </div>
                        </div>
                    )}
                    {searchedItems.map((item) => renderItem(item))}
                </div>
            </div>
        </div>
    )
}
