import { InputErrorMessage } from 'components/enrollForm/InputErrorMessage'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import OneSignal from 'react-onesignal'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import {
    Col,
    Container,
    Row,
    Search,
    Spinner,
    TimedAlert,
    TopBar
} from '../../components'
import {
    capitalizeWord,
    constants,
    getConnectUser,
    permissionFlags,
    quickSearchValidationRules
} from '../../helpers'
import { findPermission } from '../../helpers/findPermission'
import {
    useBindDispatch,
    useGetStorage,
    useHandleNotificationPermissionChange,
    useOnChange,
    useValidation
} from 'hooks'
import './home.css'
import { TargetList } from './TargetList'

export const Home = () => {
    const [animate, setAnimate] = useState(false)
    const navigate = useNavigate()
    const [targetScrollPos, setTargetScrollPos] = useState(0)
    const { t } = useTranslation()
    const [topBarTitle, setTopBarTitle] = useState(t('welcome'))
    const [changeIconColorTo, setChangeIconColorTo] = useState(false)
    const { areNotificationsAllowed, updateOneSignalSubscription } =
        useHandleNotificationPermissionChange(
            OneSignal.Notifications.permissionNative
        )
    const nodeRef = useRef(null)
    const inputRef = useRef(null)
    const targetScrollRef = useRef(null)
    const {
        veHomeHandler,
        navbarHandler,
        storeVillageClientListHandler,
        villageClientListHandler,
        storeEnrollmentFormHandler,
        getUserPermissionHandler,
        storeVillageOrdersHandler,
        transactionsHandler,
        fetchInteractionsHandler,
        clientSearchHandler,
        clearClientSearchHandler,
        veCardInventoryHandler
    } = useBindDispatch()
    const { veVillages, zoneSFID, username, country } = getConnectUser()

    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)
    const { SHOW_NAVBAR, CLIENT_LIST_EXPIRATION_NAME, ANIMATION_TIMEOUT } =
        constants
    const {
        loading,
        userData,
        error: homePageError
    } = useSelector((store) => store.home)

    const { permissionsError, permissions } = useSelector(
        ({ userPermissions }) => userPermissions
    )

    const { inputText, onChangeHandler } = useOnChange({
        query: ''
    })

    const queryValidationRules = quickSearchValidationRules()

    const { errors, validateForm } = useValidation(
        inputText,
        queryValidationRules
    )

    const canViewTargetList = findPermission(
        permissions,
        constants.CAN_VIEW_TARGET_LIST
    )

    const canUseFarmerId = findPermission(
        userPermissions,
        permissionFlags.canUseFarmerId
    )
    const TOP_BAR_HEIGHT = 47

    const setOneSignalExternalId = (externalId) =>
        process.env.REACT_APP_ONE_SIGNAL_APP_ID && OneSignal.login(externalId)

    const checkUserSubscriptionAndSetExternalId = async () => {
        if (
            areNotificationsAllowed &&
            OneSignal.User.PushSubscription.optedIn
        ) {
            await setOneSignalExternalId(username)
        } else {
            await updateOneSignalSubscription()
            await setOneSignalExternalId(username)
        }
    }

    // fetch ve data
    useEffect(() => {
        setAnimate(true)
        // show menu bar
        navbarHandler(SHOW_NAVBAR)

        clearClientSearchHandler()
        localStorage.removeItem(constants.SEARCH_RESULT)

        if (userData.length < 1) {
            veHomeHandler({
                veCode: username,
                country
            })
        }

        // set page title
        document.title = t('home.pageTitle')
    }, [])

    useEffect(() => {
        checkUserSubscriptionAndSetExternalId()
    }, [areNotificationsAllowed])

    useEffect(() => {
        if (permissions?.length < 1 || userPermissions?.length < 1) {
            getUserPermissionHandler()
        }
    }, [permissions?.length, userPermissions?.length])

    useEffect(() => {
        // get client list expiration time
        const clientListExpiration = JSON.parse(
            localStorage.getItem(CLIENT_LIST_EXPIRATION_NAME)
        )
        const currentTime = new Date().getTime()
        const isClientListExpired =
            veVillages?.length > 0 &&
            clientListExpiration &&
            currentTime > parseFloat(clientListExpiration[veVillages[0].sf_id])

        if (
            (veVillages?.length > 0 && !clientListExpiration) ||
            isClientListExpired
        ) {
            storeVillageClientListHandler({
                villages: veVillages
            })
            storeVillageOrdersHandler(veVillages)
        }
        if (veVillages?.length > 0) {
            veVillages.forEach((village) => {
                villageClientListHandler({ villageId: village.sf_id })
            })
        }
        if (veVillages?.length > 0) {
            fetchInteractionsHandler({
                villageIds: veVillages?.map((village) => village.sf_id) || [],
                surveyNames: [
                    constants.FARMER_AWARENESS_SURVEY_NAME,
                    constants.TARGET_LIST_SURVEY_NAME
                ]
            })
        }

        veVillages?.forEach((village) => {
            transactionsHandler({ villageId: village.sf_id })
        })

        const isEnrollmentFormCached = JSON.parse(
            localStorage.getItem(constants.ENROLLMENT_FORM_CACHED_NAME)
        )
        if (!isEnrollmentFormCached && zoneSFID) {
            storeEnrollmentFormHandler({ zoneSFID })
        }
    }, [userData])

    useEffect(() => {
        if (targetScrollPos < -TOP_BAR_HEIGHT) {
            setTopBarTitle(t('targetList.targetListHeaderTitle'))
        } else {
            setTopBarTitle(t('welcome'))
        }
    }, [targetScrollPos])

    useEffect(() => {
        function getScrollPos() {
            const pos = targetScrollRef.current?.getBoundingClientRect().top
            setTargetScrollPos(pos)
        }

        window.addEventListener('scroll', getScrollPos)
        getScrollPos()

        return () => window.removeEventListener('scroll', getScrollPos)
    }, [])

    useEffect(() => {
        validateForm()
        if (inputRef.current) {
            inputRef.current.style.border = 'none'
            setChangeIconColorTo('#16A34A')
        }
    }, [inputText.query, inputRef.current])

    useEffect(() => {
        veCardInventoryHandler(username)
    }, [username])

    const { name } = userData[0] || { name: '' }
    const villageIds = veVillages?.map((village) => village.sf_id) || []

    const handleClientSearch = () => {
        if (!inputText.query || errors?.query) {
            inputRef.current.focus()
            inputRef.current.style.border = '1px solid red'
            return setChangeIconColorTo('red')
        }
        clientSearchHandler({
            searchValue: {
                fullName: inputText.query,
                clientId: inputText.query
            },
            villageIds
        })
        navigate('/client-search/result')
    }

    const homePageContent = (ve) => {
        if (!ve) {
            return
        }

        return (
            <>
                <div className="header-bar" id="header-bar">
                    <Container>
                        <TopBar>{topBarTitle}</TopBar>
                    </Container>
                </div>
                <CSSTransition
                    unmountOnExit
                    timeout={ANIMATION_TIMEOUT}
                    in={animate}
                    classNames="generic"
                    appear
                    nodeRef={nodeRef}
                >
                    <div className="page-wrapper" ref={nodeRef}>
                        <Container className="home-main">
                            <Row className="align-items-center">
                                <Col md={6}>
                                    <h2
                                        className={`home-welcome--text${
                                            canUseFarmerId ? '-large' : ''
                                        } header-welcome`}
                                    >
                                        {t('home.greetings')}{' '}
                                        {capitalizeWord(name.split(' ')[0])}
                                    </h2>
                                </Col>
                            </Row>
                            {canUseFarmerId && (
                                <Row className="justify-content-center">
                                    <Col
                                        md={12}
                                        className="search-input-container"
                                    >
                                        <Search
                                            inputRef={inputRef}
                                            iconSize={30}
                                            iconColor={changeIconColorTo}
                                            iconPos="left"
                                            style="rounded"
                                            name="query"
                                            onChange={onChangeHandler}
                                            onSearchHandler={handleClientSearch}
                                            placeholder={t(
                                                'searchInputPlaceholder'
                                            )}
                                            data-testid="quick-search-input"
                                        />
                                        {errors?.query && (
                                            <InputErrorMessage
                                                errorMessage={errors?.query}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            )}
                            {canViewTargetList && (
                                <TargetList
                                    targetScrollRef={targetScrollRef}
                                    veCode={username}
                                />
                            )}
                        </Container>
                    </div>
                </CSSTransition>
            </>
        )
    }

    const homePage = loading ? (
        <Spinner size={'90'} pageSpinner={true} fullscreen={true} />
    ) : homePageError || permissionsError ? (
        <TimedAlert type="floating" status="error">
            {homePageError || permissionsError}
        </TimedAlert>
    ) : (
        homePageContent(userData[0])
    )

    return homePage
}
