import React, { useEffect, useMemo, useState, useRef } from 'react'

import { Menu, MenuChild } from '../components'
import { isAuthenticated, getConnectUser } from 'helpers'
import { useBindDispatch, useScreenSize, useAppSelector } from '../hooks'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { navBarConfig } from 've/navigation/navBarConfig'
import { navBarConfig as FcNavBarConfig } from 'fc/navigation/navBarConfig'

const Nav = () => {
    const screenSize = useScreenSize()
    const {
        getUserPermissionHandler,
        clearClientSearchHandler,
        showWarningModalHandler,
        fetchUnreadNotificationsHandler
    } = useBindDispatch()
    const location = useLocation()
    const [permissionsState, setPermissions] = useState([])
    const isMobile = screenSize.screenWidth <= 800
    const { username, role } = getConnectUser()
    const isStaffAuthenticated = isAuthenticated()

    const { permissions } = useSelector(
        ({ userPermissions }) => userPermissions
    )

    const { isVisited } = useAppSelector((state) => state.isVisited)

    if (!isStaffAuthenticated) {
        return
    }

    const connectUserRoleConfig = {
        VE: (param) => navBarConfig(param),
        FC: (param) => FcNavBarConfig(param)
    }

    const { mobileMenuConfig, desktopMenuConfig } = connectUserRoleConfig[role](
        {
            permissionsState,
            clearClientSearchHandler
        }
    )

    useMemo(() => {
        if (username) {
            fetchUnreadNotificationsHandler()
        }
    }, [username])

    const hasFetchedPermissionsRef = useRef(false)

    useEffect(() => {
        if (permissions && permissions.length > 0 && !hasFetchedPermissionsRef.current) {
            setPermissions(permissions)
            hasFetchedPermissionsRef.current = true
        }

        if (isStaffAuthenticated && !hasFetchedPermissionsRef.current) {
            getUserPermissionHandler()
        }
    }, [permissions?.length, isStaffAuthenticated])

    const isVisitedRoute = location.pathname === '/visit'

    const visitWarningHandler = (handlerOnClickCallback) => {
        if (isVisitedRoute) {
            showWarningModalHandler(true)
            return
        }

        return handlerOnClickCallback && handlerOnClickCallback()
    }

    const mobileMenu = (
        <>
            {mobileMenuConfig.map(
                (
                    {
                        name,
                        path,
                        href,
                        icon,
                        disableActive,
                        menuOption,
                        onClickCallback,
                        isVisible
                    },
                    idx
                ) => (
                    <MenuChild
                        icon={icon}
                        href={href}
                        path={path}
                        key={idx}
                        onClickCallback={
                            !isVisited
                                ? () => visitWarningHandler(onClickCallback)
                                : onClickCallback
                        }
                        disableActive={disableActive}
                        menuOption={isVisitedRoute ? '' : menuOption}
                        isVisible={{ isVisible, idx }}
                        id={`${path}-menu`}
                    >
                        {name}
                    </MenuChild>
                )
            )}
        </>
    )

    const desktopMenu = (
        <>
            {desktopMenuConfig
                .filter((config) => config.isVisible)
                .map(
                    (
                        {
                            name,
                            path,
                            href,
                            icon,
                            disableActive,
                            onClickCallback,
                            isVisible
                        },
                        idx
                    ) => {
                        return (
                            <MenuChild
                                icon={icon}
                                href={href}
                                onClickCallback={
                                    !isVisited
                                        ? () =>
                                              visitWarningHandler(
                                                  onClickCallback
                                              )
                                        : onClickCallback
                                }
                                path={path}
                                key={idx}
                                disableActive={disableActive}
                                id={`${path}-menu`}
                                isVisible={{ isVisible, idx }}
                            >
                                {name}
                            </MenuChild>
                        )
                    }
                )}
        </>
    )

    return (
        <Menu type={isMobile ? 'mobile menu' : 'top menu'}>
            {isMobile && mobileMenu ? mobileMenu : desktopMenu}
        </Menu>
    )
}

export default Nav
