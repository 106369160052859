import { zip } from 'lodash'
import { VeType } from 'storeTypes/vesTypes'
import { VillageType } from './appTypes'

const splitByComma = (str: string) => str.split(',').map((s) => s.trim())

const extractVillageNameAndCode = (ve: VeType) => {
    const villageNames = splitByComma(ve.current_villages)
    const villageCodes = splitByComma(ve.current_village_codes)

    return zip(villageNames, villageCodes) as [string, string][]
}

export const extractVillages = (ves: VeType[]): Partial<VillageType>[] => {
    const villages = {} as Record<string, Partial<VillageType>>

    ves.forEach((ve) => {
        extractVillageNameAndCode(ve).forEach(([name, code]) => {
            if (villages[code]) {
                villages[code]?.veNames?.push(ve.name || '')
            } else {
                villages[code] = {
                    name,
                    code,
                    veNames: [ve.name || '']
                }
            }
        })
    })

    return Object.values(villages)
}
