import { extractVillages, VillageType } from 'helpers'
import { VesActionEnum, VesActionType, VeType } from 'storeTypes/vesTypes'

type VesStateType = {
    loading: boolean
    ves: VeType[]
    villages: VillageType[]
    error?: string
}

const initialState = {
    loading: false,
    ves: [],
    villages: [],
    error: ''
}

export const vesReducer = (
    state: VesStateType = initialState,
    action: VesActionType
) => {
    switch (action.type) {
        case VesActionEnum.FETCHING_VES:
            return {
                ...state,
                loading: true
            }
        case VesActionEnum.FETCH_VES_SUCCESS: {
            const ves = action.payload as VeType[]
            const villages = extractVillages(ves) as VillageType[]
            return {
                ...state,
                loading: false,
                ves,
                villages
            }
        }
        case VesActionEnum.FETCH_VES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload as string
            }
        default:
            return state
    }
}
